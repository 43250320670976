import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { SEO } from 'components';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PrevArrow from 'images/shared/PrevArrow';
import { Drag, Options } from 'images/shared';

const SanityCMS = () => {
  const data = useStaticQuery(
    graphql`
      query {
        signIn: file(relativePath: { eq: "guides/sanity-cms/sign-in.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              width: 393
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        existing: file(relativePath: { eq: "guides/sanity-cms/existing-document.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        title: file(relativePath: { eq: "guides/sanity-cms/title.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        slug: file(relativePath: { eq: "guides/sanity-cms/slug.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        richText: file(relativePath: { eq: "guides/sanity-cms/rich-text.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        image: file(relativePath: { eq: "guides/sanity-cms/image.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        reference: file(relativePath: { eq: "guides/sanity-cms/reference.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        singleselect: file(relativePath: { eq: "guides/sanity-cms/dropdown-selection.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        multiselect: file(relativePath: { eq: "guides/sanity-cms/multiselection.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        list: file(relativePath: { eq: "guides/sanity-cms/array-order.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        listOptions: file(relativePath: { eq: "guides/sanity-cms/array-options.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        toggle: file(relativePath: { eq: "guides/sanity-cms/boolean-selection.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
        notPublished: file(relativePath: { eq: "guides/sanity-cms/not-published.webp" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: DOMINANT_COLOR
              formats: [AUTO, WEBP]
              quality: 50
            )
          }
        }
      }
    `
  );

  return (
    <>
      <SEO pageSpecificTitle="Guide to using Sanity CMS" />
      <Wrapper>
        <InnerWrapper>
          <BackButton to="/">
            <PrevArrow width="1.688rem" />
          </BackButton>
          <h1>
            Guide to <span>using Sanity CMS</span>{' '}
          </h1>
          <br />
          <Body>
            <Item style={{ maxWidth: '1024px' }}>
              <h3>Accessing the CMS</h3>
              <GridColumn>
                <ol>
                  <li>Open the Sanity CMS at the provided URL.</li>
                  <li>Log in using your registered credentials (if applicable).</li>
                </ol>
                <GatsbyImage image={getImage(data.signIn)} style={{ maxWidth: '393px'}} alt="Accessing the CMS" />
              </GridColumn>
            </Item>
            <p>
              Once logged in, you'll see a list of documents. These represent pages of your website,
              and the content within these pages.
              <br />
              You can choose to edit already existing documents, or a create new document.
            </p>
            <Item>
              <h3>Editing an existing page or document</h3>
              <p>
                To the left will be a list of the available documents types. Choose the document
                type that corresponds to the page, then select the page from the list of pre-existing
                documents.
              </p>
              <br />
              <GatsbyImage image={getImage(data.existing)} alt="Existing document in CMS" />
            </Item>
            <Item>
              <h3>Creating a new page or document</h3>
              <p>
                To create a new page or document, you can either click + Create in the dashboard
                header or to the left will be a list of the available document types you can create.
                Choose a document type and click + in the top right hand corner to start a new
                document.
              </p>
              <br />
              <p>
                You will be presented with a number of fields to populate. Some will be required to publish the document or page, these fields will be marked with an *
                <br /><br />
                Let's go over some of the essential fields:
              </p>
              <br />
              <p>
                <strong>a. Title</strong>
              </p>
              <IndentedParagraph>
                <p>
                  <span>What is it?</span> The name of the page or document.
                </p>
                <p>
                  <span>How to fill:</span> Add a concise and clear title that represents the
                  content.
                </p>
              </IndentedParagraph>
              <br />
              <GatsbyImage image={getImage(data.title)} alt="Title field in CMS" />
              <br />
              <p>
                <strong>b. Slug (URL)</strong>
              </p>
              <IndentedParagraph>
                <p>
                  <span>What is it?</span> The URL endpoint for the page.
                </p>
                <p>
                  <span>How to fill:</span> Typically generated automatically based on the title, but
                  you can customize it.
                  <ul>
                    <li>Click on "Generate" or manually input the slug</li>
                    <li>Ensure there are no spaces and use hyphens to separate words.</li>
                  </ul>
                </p>
              </IndentedParagraph>
              <br />
              <GatsbyImage image={getImage(data.slug)} alt="Slug field in CMS" />
              <br />
            </Item>
            <Item>
              <p>
                <strong>Adding content to the document</strong>
              </p>
              <p>
                Next, you'll populate a number of fields depending on the document you're creating. The field types may include plain text, rich text, selections or uploads.
              </p>
              <br />
              <p>
                <strong>a. Plain text</strong>
              </p>
              <IndentedParagraph>
                <p>
                  <span>What is it?</span> Simple text with no special formatting.
                </p>
                <p>
                  <span>How to fill::</span> Use the provided text area to input the content. You can
                  type or paste your text. These are great for short descriptions or summaries.
                </p>
              </IndentedParagraph>
              <br />
              <p>
                <strong>b. Rich Text</strong>
              </p>
              <IndentedParagraph>
                <p>
                  <span>What is it?</span> Text that can include formatting like bold, italics,
                  bullet points, and links.
                </p>
                <p>
                  <span>How to fill:</span> In the rich text editor, use the available toolbar to
                  format your text.
                </p>
              </IndentedParagraph>
              <br />
              <GatsbyImage image={getImage(data.richText)} alt="Rich text field in CMS" />
              <br />
              <p>
                <strong>c. Uploading images</strong>
              </p>
              <IndentedParagraph>
                <ul>
                  <li>
                    In the image field, simply Drag & Drop your image or click &apos;Upload&apos; or &apos;Select&apos;
                    to upload a new image from your computer or choose an existing one from the
                    media library.
                  </li>
                  <li>
                    Ensure the image meets any size or format requirements that might be highlighted
                    in the description (usually JPG, PNG).
                  </li>
                </ul>
                <br />
                <p>
                  <strong>Alt text</strong>
                </p>
                <p>
                  <span>What is it?</span> Text describing the image, used for accessibility and
                  SEO.
                </p>
                <p>
                  <span>How to fill:</span> Input a short description of what the image shows.
                </p>
              </IndentedParagraph>
              <br />
              <GatsbyImage image={getImage(data.image)} alt="Image field in CMS" />
              <br />
            </Item>
            <Item>
              <p>
                Depending on your website, there might be other fields like metadata, tags,
                categories, lists, dropdowns or even references to other documents in the CMS.
              </p>
              <br />
              <p>
                <strong>a. Reference</strong>
              </p>
              <IndentedParagraph>
                <p>
                  <span>What is it?</span> A reference to another document in the CMS.
                </p>
                <p>
                  <span>How to fill:</span> In a reference field you can search for, browse and
                  select references to other documents, or create new documents of the appropriate
                  type in a new pane. These are useful for linking to categories, authors, etc.
                </p>
                <br />
                <GatsbyImage image={getImage(data.reference)} alt="Reference field in CMS" />
                <br />
              </IndentedParagraph>
              <p>
                <strong>b. Single Selection</strong>
              </p>
              <IndentedParagraph>
                <p>
                  <span>What is it?</span> A single selection from a list of predetermined options.
                </p>
                <p>
                  <span>How to fill:</span> Normally presented as a dropdown, choose one of the
                  options provided.
                </p>
                <br />
                <GatsbyImage
                  image={getImage(data.singleselect)}
                  alt="Single selection field in CMS"
                />
                <br />
              </IndentedParagraph>
              <br />
              <p>
                <strong>c. Multi Selection</strong>
              </p>
              <IndentedParagraph>
                <p>
                  <span>What is it?</span> One or more selections from a list of predetermined
                  options.
                </p>
                <p>
                  <span>How to fill:</span> Normally presented as a grid, choose one or more of the
                  options provided. These are useful for assigning tags to pages.
                </p>
                <br />
                <GatsbyImage image={getImage(data.multiselect)} alt="Multiselect field in CMS" />
                <br />
              </IndentedParagraph>
              <br />
              <p>
                <strong>d. List or Group</strong>
                <IndentedParagraph>
                  <p>
                    <span>What is it?</span> An ordered list of data, each list item may contain one
                    or more fields.
                  </p>
                  <p>
                    <span>How to fill:</span> Add item to the list and fill in any of the required
                    fields. You can drag to re-order items within the list by clicking and dragging
                    the corresponding <Drag /> icon.
                  </p>
                  <br />
                  <GatsbyImage image={getImage(data.list)} alt="List field in CMS" />
                  <br />
                  <p>
                    You can remove or duplicate an item, by clicking the corresponding <Options /> icon.
                  </p>
                  <br />
                  <GatsbyImage image={getImage(data.listOptions)} alt="List field options in CMS" />
                  <br />
                </IndentedParagraph>
              </p>
              <br />
              <p>
                <strong>e. Toggle</strong>
                <IndentedParagraph>
                  <p>
                    <span>What is it?</span> A boolean field with description of it's use.
                  </p>
                  <p>
                    <span>How to fill:</span> Toggle on or off depending on the field description.
                  </p>
                  <br />
                  <GatsbyImage image={getImage(data.toggle)} alt="Toggle field in CMS" />
                  <br />
                </IndentedParagraph>
              </p>
            </Item>
            <Item>
              <h3>Save or publish a document or changes</h3>
              <p>
                As you're adding or making changes the CMS with automatically save and keep the
                document as a draft.
              </p>
              <p>
                At the end of each document you will see a tab with the status of the document,
                showing whether any changes have been made and whether they are published or not.
              </p>
              <br />
              <GatsbyImage
                image={getImage(data.notPublished)}
                alt="Document not published in CMS"
              />
              <br />
              <p>
                When you are ready to publish a new document or changes to an existing document,
                click &apos;Publish&apos; in the bottom right hand corner.
              </p>
              <br />
              <p>
                This will trigger a new build of the live site, it may take up to about 5-10 minutes
                but once the build is complete you should see the new pages or changes on the live
                site.
              </p>
            </Item>
            <p style={{ fontWeight: '300', color: 'var(--ax-gold)'}}>If you need more custom guidance, we can always dive deeper into other features or more complex document settings.</p>
          </Body>
        </InnerWrapper>
      </Wrapper>
    </>
  );
};

const BackButton = styled(Link)`
  align-items: center;
  border-radius: 0.125rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  margin-top: -0.75em;
  margin-bottom: 2rem;
  transition: 150ms ease-in-out;
  width: 3rem;

  &:hover {
    background-color: #000;
  }

  &:not(:hover) {
    background-color: var(--ax-gold);
  }

  &:active {
    background-color: #c1beac;
  }
`;

const SeeMore = styled.button`
  width: auto;
  outline: none;
  border: none;
  background: none;
  display: inline-block;
  border-bottom: 3px solid black;
  color: black;
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  text-align: left;
  padding: 0.75rem;
  padding-left: 0;
  position: absolute;
  bottom: 1rem;
  transition: 0.375s;
  transform: translateY(20px);
  width: 100%;
  opacity: 0;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  @media (min-width: 1150px) {
    font-size: 1.25rem;
    gap: 3rem;
  }
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  ul {
    margin-left: 1rem;
  }
  ol {
    margin-left: -1rem;
  }
  svg {
    height: 1rem;
    max-width: 1rem;
  }
`;

const GridColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  /* @media screen and (min-width: 48rem) {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 3rem;
  } */
`;

const IndentedParagraph = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  span {
    color: var(--ax-gold);
    font-weight: 300;
  }
`;

const InnerWrapper = styled.div`
  grid-column: 2 / 3;
  width: 100%;

  @media screen and (min-width: 48rem) {
    margin-top: -4.825rem;
  }

  @media screen and (min-width: 68.75rem) {
    margin: -3.5rem 0 9.1rem;
  }
`;

const Wrapper = styled.section`
  padding-top: 7.1rem;
  position: relative;
  h1 {
    font-size: 3rem;
    font-weight: 900;
    letter-spacing: 0.01em;
    line-height: 1em;
    vertical-align: middle;
    margin-bottom: 1em;
    span {
      font-size: 0.75em;
      color: var(--ax-gold);
      font-weight: 300;
    }
  }

  @media screen and (min-width: 48rem) {
    padding-top: 11.9rem;
    min-height: auto;
    h1 {
      font-size: 5rem;
      font-weight: 900;
      line-height: 1.219em;
      span {
        font-size: 0.9em;
      }
    }
  }
`;

export default SanityCMS;
